import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import size from 'lodash/size'
import axios from "axios/index";
import i18n from "@/lang/i18n";

const state = {
  dialog_store: {
    drawing: {
      dropX: 0,
      dropY: 0
    },

    dialog: {
      max_id: {
        node: 0,
        translate: 0
      },
      params: {
        save_listener_installed: false,
        entry_section_id: null,
        current_section_id: null,
        current_section_loaded: false,
        current_lang_code: null,

      },
      sections: [],
      loaded_sections: [],

      nodes: {},
      //объект: {section_id:{lang_code:{translate_id: text}}}
      translations: {},

      constants: {
        translations: {},
        list: []
      }
    },

    //В эту переменную записывается объект dialog
    dialog_default: null,

    active_node_id: null,
    //Сценарий
  }
}

const actions = {

  /**
   * Loads bot constants, sections and nodes
   * @method loadDialogData
   */

  async loadDialogData({dispatch, commit, state}) {
    console.log('<constructor> [INFO] dialog_store loadData for bot id ' + state.bot_store.botSettings.id);
    dispatch('updateAjaxDialog', [true, true]);
    commit('SET_DEFAULT_DIALOG')
    commit('SET_DIALOG_TO_DEFAULT')
    commit('SET_DIALOG_DATA_FROM_JSON', state.bot_store.botSettings.json_data)
    await dispatch('axiosGetBotConstants')
    await dispatch('axiosGetBotSections')
    await dispatch('getDefaultAndCurrentSection')
    await dispatch('axiosGetCurrentSectionData')

    //@deprecated
    //dispatch('addDefaultSectionIfNotExist')
    //dispatch('initTranslates')
  },

  async resetDialogData() {
    console.log('reset dialog data');
  },

  /**
   * Get bot constants
   * @method axiosGetBotConstants
   */
  async axiosGetBotConstants({commit, dispatch, state}) {

    let token = Vue.$cookies.get('token_e')
    let url = state.engine_settings.active_url
    let bot_id = state.bot_store.botSettings.id
    // state.constants = {
    //   translations: {},
    //   list: []
    // }

    if (url && token && state.engine_settings.active_id && bot_id) {
      await axios.get(url + '/kw/constants/' + bot_id + '/', {
        headers: {
          "X-CSRF-TOKEN": token
        }
      }).then((response) => {
        if (response.data && response.data.data) {
          if (response.data.data && response.data.data.constants && response.data.data.constants.json_data) {
            let constants = JSON.parse(response.data.data.constants.json_data)
            commit('SET_CONSTANTS', constants)
          } else {
            dispatch('updateAjaxDialogError', ['Error', 'Get sections error', i18n.t('modules.dialog.texts.settings.bot_data_not_loaded')]);
            console.log("<constructor> [ERROR] axiosGetBotConstants error")
          }
        }

      }, (err) => {
        dispatch('updateAjaxDialogError', [err, 'Error', 'Get sections error', i18n.t('modules.dialog.texts.settings.bot_data_not_loaded')]);
        console.log("<constructor> [ERROR] axiosGetBotConstants error")
      });

    }
  },


  /**
   * Get sections of active bot
   * @method axiosGetBotSections
   */

  async axiosGetBotSections({commit, dispatch, state}) {

    let token = Vue.$cookies.get('token_e')
    let url = state.engine_settings.active_url
    let bot_id = state.bot_store.botSettings.id

    // state.sections = []
    // state.nodes = {}


    if (url && token && state.engine_settings.active_id && bot_id) {

      //clear loaded sections
      state.dialog_store.dialog.loaded_sections = []

      await axios.get(url + '/kw/sections/' + bot_id + '/', {
        headers: {
          "X-CSRF-TOKEN": token
        }
      }).then((response) => {
        if (response.data && response.data.data) {
          if (response.data.data && response.data.data.sections) {
            let sections = response.data.data.sections
            commit('SET_SECTIONS', sections)
          }
        } else {
          dispatch('updateAjaxDialogError', ['Error', 'Error getting bot sections']);
          console.log("<constructor> [ERROR] axiosGetBotSections error")
        }

      }, (err) => {
        dispatch('updateAjaxDialogError', [err, 'Error', 'Error getting bot sections']);
        console.log("<constructor> [ERROR] axiosGetBotSections error")
      });

    }
  },


  /**
   * Get current section data
   * @method axiosGetCurrentSectionData
   */
  async axiosGetCurrentSectionData({commit, dispatch, state}) {
    //Если текущая секция не определена - выход
    if (!state.dialog_store.dialog.params.current_section_id) {
      console.log('<constructor>[WARNING] no current section id set')
      return false
    }

    //Если текущая секция уже загружена - выход
    if (state.dialog_store.dialog.loaded_sections.includes(state.dialog_store.dialog.params.current_section_id)) {
      commit('SET_DIALOG_PARAMS', {field: 'current_section_loaded', value: true})
      return false
    }


    //Load section data
    if (!(await dispatch('axiosGetCurrentSectionNodesData'))) {
      dispatch('updateAjaxDialog', [true, false, 'Error', 'Section elements load error']);
      console.log("<constructor> [ERROR] axiosGetCurrentSectionNodesData error")
    }
    //Load section translation
    if (!(await dispatch('axiosGetCurrentSectionTranslationsData'))) {
      dispatch('updateAjaxDialogError', ['Error', 'Error getting section translations']);
      console.log("<constructor> [ERROR] axiosGetCurrentSectionTranslationsData error")
    }

    //If current section is not included in loaded_sections
    if (!state.dialog_store.dialog.loaded_sections.includes(state.dialog_store.dialog.params.current_section_id))
      state.dialog_store.dialog.loaded_sections.push(state.dialog_store.dialog.params.current_section_id)

    commit('SET_DIALOG_PARAMS', {field: 'current_section_loaded', value: true})

  },

  /***
   * Get current section nodes
   * @method axiosGetCurrentSectionNodesData
   */
  async axiosGetCurrentSectionNodesData({commit, dispatch, state}) {

    console.log('<constructor> [INFO] axiosGetCurrentSectionNodesData for section id ' + state.dialog_store.dialog.params.current_section_id)


    let token = Vue.$cookies.get('token_e')
    let url = state.engine_settings.active_url
    let section_id = state.dialog_store.dialog.params.current_section_id

    let result = false
    let add_entry_point_node = false

    //console.log(cloneDeep(state.dialog_store.dialog.nodes), 'dialog.nodes before load')
    if (url && token && section_id) {

      //  console.log(section_id)

      await axios.get(url + '/kw/nodes/' + section_id + '/', {
        headers: {
          "X-CSRF-TOKEN": token
        }
      }).then((response) => {
        if (response.data && response.data.data && response.data.data.nodes) {

          let nodes = response.data.data.nodes

          //console.log(nodes)

          //todo eb - мерджить свойства ноды с эталонным значением из стора для обновления новых свойств
          //Поиск стартовой ноды
          let entry_point_node_exist = false
          for (let node_id in nodes) {
            let node = nodes[node_id]
            if (node && node.type && node.type === 'entry_point') {
              entry_point_node_exist = true
            }
          }
          if (!entry_point_node_exist) {
            add_entry_point_node = true
          }

          commit('ADD_SECTION_NODES', nodes)
          result = true
          //console.log(cloneDeep(state.dialog_store.dialog.nodes), 'dialog.nodes after load')
        } else {
          dispatch('updateAjaxDialogError', ['Error', 'Error getting section nodes']);
          console.log("<constructor> [ERROR] axiosGetCurrentSectionNodesData error")
        }

      }, (err) => {
        dispatch('updateAjaxDialogError', [err, 'Error', 'Error getting section nodes']);
        console.log("<constructor> [ERROR] axiosGetCurrentSectionNodesData error")
      });

    } else {
      dispatch('updateAjaxDialogError', ['Error', 'Error getting section nodes: no ID']);
      console.log("<constructor> [ERROR] axiosGetCurrentSectionNodesData error, no ID")
    }

    //Добавление стартовой ноды если она не существует
    if (add_entry_point_node) {
      console.log('<constructor>[INFO] entry point does not exist, creating')
      dispatch('addNewNode', {type: 'entry_point', x: 10, y: 10})
    }

    return result

  },

  /**
   * Loads current sections translations data
   * @method axiosGetCurrentSectionTranslationsData
   */
  async axiosGetCurrentSectionTranslationsData({commit, dispatch, state}) {

    let token = Vue.$cookies.get('token_e')
    let url = state.engine_settings.active_url
    let section_id = state.dialog_store.dialog.params.current_section_id

    let result = false

    if (url && token && section_id) {

      await axios.get(url + '/kw/translations/' + section_id + '/', {
        headers: {
          "X-CSRF-TOKEN": token
        }
      }).then((response) => {
        if (response.data && response.data.data && response.data.data.translations) {
          let translations = response.data.data.translations
          commit('ADD_SECTION_TRANSLATIONS', {section_id: section_id, translations: translations})
          result = true
          dispatch('updateAjaxDialog', [false, false]);
        } else {
          dispatch('updateAjaxDialogError', ['Error', 'Error getting section translations']);
          console.log("<constructor> [ERROR] axiosGetCurrentSectionTranslationsData")
        }
      }, (err) => {
        dispatch('updateAjaxDialogError', [err, 'Error', 'Error getting section translations']);
        console.log("<constructor> [ERROR] axiosGetCurrentSectionTranslationsData")
      });

    }

    return result

  },

  //Опрделение дефолтной и текущей секции
  getDefaultAndCurrentSection({commit, state, getters}) {

    let new_entry_section_id = null
    //Цикл по секциям, определение дефолтной секции и если текущая секция не определена - записываем в нее значение дефолтной
    for (let section of state.dialog_store.dialog.sections) {
      if (section.default) {
        new_entry_section_id = section.id

        if (!state.dialog_store.dialog.params.current_section_id) {
          commit('SET_DIALOG_PARAMS', {field: 'current_section_id', value: section.id})
        }
      }
    }

    //Установка нового значения
    commit('SET_DIALOG_PARAMS', {field: 'entry_section_id', value: new_entry_section_id})

    //Исли текущая секция определена но ее нет в списке секций - очищаем
    if (state.dialog_store.dialog.params.current_section_id && !getters.sections_obj[state.dialog_store.dialog.params.current_section_id]) {
      commit('SET_DIALOG_PARAMS', {field: 'current_section_id', value: null})
    }

    //Исли текущая не определена и есть по умолчанию
    if (!state.dialog_store.dialog.params.current_section_id && new_entry_section_id) {
      commit('SET_DIALOG_PARAMS', {field: 'current_section_id', value: new_entry_section_id})
    }

    //Исли текущая не определена
    if (!state.dialog_store.dialog.params.current_section_id && state.dialog_store.dialog.sections[0] && state.dialog_store.dialog.sections[0].id) {
      commit('SET_DIALOG_PARAMS', {field: 'current_section_id', value: state.dialog_store.dialog.sections[0].id})
    }

  },

  //Dialog
  incrementMaxId({commit}, entity) {
    commit('INCREMENT_MAX_ID', entity)
  },

  addSection({commit, getters}, section) {
    commit('INCREMENT_MAX_ID', 'section')
    section.id = getters.current_max_section_id
    commit('ADD_SECTION', section)
  },

  // /**
  //  * Clone section
  //  * @method cloneSection
  //  * @param commit
  //  */
  // cloneSection({commit, state}, section_id) {
  //
  // },


  cloneNodeById({commit, state, getters}, {node_id, params}) {

    //Проверки
    if (!(getters.current_section_id && state.dialog_store.dialog.nodes && state.dialog_store.dialog.nodes[node_id] && state.dialog_store.dialog.nodes[node_id].params)) {
      return false
    }

    let node = cloneDeep(state.dialog_store.dialog.nodes[node_id]);

    if (params.x && params.y) {
      node.x = params.x
      node.y = params.y
    }

    //Установка нового идентификатора
    commit('INCREMENT_MAX_ID', 'node')
    node.id = getters.current_max_node_id

    //Копирование переводов и обнуление связей
    //Базовое обнуление связи
    node.next_element_id = null

    //Базовые параметры
    if (['message', 'input', 'keyboard', 'search_list'].includes(node.type)) {
      for (let param_field of ['text']) {
        if (node.params[param_field]) {
          //Получение нового идентификатора перевода
          commit('INCREMENT_MAX_ID', 'translate')
          let new_translate_id = getters.current_max_translate_id

          //Поиск переводов на всех языках и копирование если есть
          commit('CLONE_NODE_FIELD_TRANSLATES', {
            translate_from_id: node.params[param_field],
            translate_to_id: new_translate_id,
            section_from_id: node.section_id,
            section_to_id: getters.current_section_id
          })

          //Установка параметру нового идентификатора перевода
          node.params[param_field] = new_translate_id
        }
      }
    }
    //Элементы клавиатуры
    //Поиск связи с удаляемым элементом в списке подэлементов
    if (node.next_elements_ids) {
      for (let next_el of node.next_elements_ids) {

        //Обнуление связи перехода
        next_el.next_element_id = null

        //Цикл по полям переводов
        if (!['action'].includes(node.type)) {
          for (let param_field of ['text']) {
            if (next_el[param_field]) {
              //Получение нового идентификатора перевода
              commit('INCREMENT_MAX_ID', 'translate')
              let new_translate_id = getters.current_max_translate_id

              //Поиск переводов на всех языках и копирование если есть
              commit('CLONE_NODE_FIELD_TRANSLATES', {
                translate_from_id: next_el[param_field],
                translate_to_id: new_translate_id,
                section_from_id: node.section_id,
                section_to_id: getters.current_section_id
              })

              //Установка параметру нового идентификатора перевода
              next_el[param_field] = new_translate_id
            }
          }
        }
      }
    }

    //Установка новой секции
    node.section_id = getters.current_section_id

    commit('ADD_NODE', node)

  },

  addNode({commit, getters}, node) {

    commit('INCREMENT_MAX_ID', 'node')
    node.id = getters.current_max_node_id

    //Инициализация переводов
    if (['message', 'input', 'keyboard', 'search_list'].includes(node.type)) {
      if (node.params && typeof node.params.text !== 'undefined' && node.params.text === null) {
        commit('INCREMENT_MAX_ID', 'translate')
        node.params.text = getters.current_max_translate_id
      }
    }
    commit('ADD_NODE', node)
  },

  removeNodeAndUpdateConnections({commit}, node) {
    commit('REMOVE_NODE_AND_UPDATE_CONNECTIONS', node)
  },

  updateNode({commit}, node) {
    commit('UPDATE_NODE', node)
  },

  setActiveNodeId({commit}, node_id) {
    commit('SET_ACTIVE_NODE_ID', node_id)
  },

  addNewNode({dispatch, getters}, {type, x, y}) {

    if (getters.current_section_id) {
      let node = cloneDeep(getters.default_node);
      let node_params = cloneDeep(getters['default_' + type + '_node_params']);
      node.type = type
      node.section_id = getters.current_section_id
      node.params = node_params
      node.x = x
      node.y = y
      dispatch('addNode', node)
    } else {
      console.log('<constructor> [ERROR] no current_section_id in addNewNode')
    }
  },
  //Dialog

  //Drawing
  setDrawingDropCoordinates({commit}, {x, y}) {
    commit('SET_DRAWING_DROP_COORDINATES', {x, y})
  },
  //Drawing

  //Translates
  setCurrentLangCode({commit}, code) {
    commit('SET_CURRENT_LANG_CODE', code)
  },

  addOrUpdateNodeTranslate({commit, state}, {lang_code, translate_id, text}) {
    commit('ADD_TRANSLATE', {section_id: state.dialog_store.dialog.params.current_section_id, lang_code, translate_id, text})
  },
  //Translates


  /**
   * @description set max_id of created nodes, added for clone of Section and Import
   * @name setDialogMaxIdOfNode
   * @param commit
   * @param payload
   */
  setDialogMaxIdOfNode({commit}, payload) {
    if (payload.max_id) {
      commit('SET_BOT_SETTING_JSON_DATA', {
        field: 'max_id',
        value: payload.max_id,
      });
    }

    commit('SET_DIALOG_DATA_FROM_JSON', payload)
  },

    deleteNodesAndTranslationsBySectionId ({ commit }, sectionId) {
        commit('DELETE_NODES_AND_TRANSLATIONS_BY_SECTION_ID', sectionId)
    },

    setNextElementsIdsActiveNode({state, getters}, array) {
        if (
          getters.active_node_id
          && state.dialog_store.dialog.nodes[getters.active_node_id]
        ) {
            Vue.set(
              state.dialog_store.dialog.nodes[getters.active_node_id],
              'next_elements_ids',
              array
            )
        }
    },

  /**
   * @name axiosSaveBot
   * @description save All dialog data with constants
   */
  axiosSaveBot({ state, commit, dispatch }) {

    dispatch('changeParameterNotSavedConstant')

    let url = state.engine_settings.active_url;
    let bot_id = parseInt(state.bot_store.botSettings.id);
    let request = cloneDeep(state.dialog_store.dialog);
    request.bot_id = bot_id;
    request.updatedAt = state.bot_store.botSettings.updatedAt;
    let token = Vue.$cookies.get('token_e');

    if (url && bot_id && token) {
      dispatch('updateAjaxDialog', [true, true]);
      axios
        .post(url + '/kw/dialog/save/', request, {
          headers: {
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          (response) => {
            dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data?.status === 'success' &&
              response.data.data?.updatedAt
            ) {
              commit('SET_BOT_SETTING', {
                field: 'updatedAt',
                value: parseInt(response.data.data.updatedAt),
              });
            } else {
              dispatch('updateAjaxDialog', [
                true,
                false,
                i18n.t('modules.dialog.texts.common.warning'),
                i18n.t('modules.dialog.texts.common.wrong_response_status'),
              ]);
            }
          },
          (err) => {
            let title = i18n.t('common.error');
            let description = i18n.t(
              'modules.dialog.texts.common.cant_save_dialog'
            );
            if (err.response.data.message === 'bot_last_update_error') {
              title = i18n.t('modules.dialog.texts.common.cant_save_dialog');
              description = i18n.t(
                'modules.dialog.texts.common.another_user_save_dialog'
              );
            }

            dispatch('updateAjaxDialogError', [
              err,
              title,
              description,
            ]);
          }
        );
    }
  },

  changeParameterNotSavedConstant ({commit}) {
    commit('CHANGE_PARAMETER_NOT_SAVED_CONSTANT')
  },

  addNewConstantToState ({ state, commit }) {
    const dialogMaxIdTranslate = state.dialog_store.dialog.max_id.translate

    let newConstant = {
      name: '',
      value: dialogMaxIdTranslate + 1,
      description: '',
      group_id: null,
      not_saved: true,
    }

    commit('ADD_CONSTANT', newConstant)
  },

  clearDialogTranslations ({commit}) {
    commit('CLEAR_DIALOG_TRANSLATIONS')
  },

  async clearDialogNodes ({commit}) {
    await commit('CLEAR_DIALOG_NODES')
  },
}

const mutations = {

  SET_DEFAULT_DIALOG(state) {
    //console.log('SET_DEFAULT_DIALOG')
    let obj = cloneDeep(state.dialog_store.dialog);
    Vue.set(state, 'dialog_default', obj)
  },

  SET_DIALOG_TO_DEFAULT(state) {
    //  console.log('SET_DIALOG_TO_DEFAULT')
    let obj = cloneDeep(state.dialog_store.dialog_default);
    Vue.set(state, 'dialog', obj)
  },

  SET_DIALOG_DATA_FROM_JSON(state, json) {


    if (json.max_id) {
      Vue.set(state.dialog_store.dialog, 'max_id', json.max_id)
    }
  },


  INCREMENT_MAX_ID(state, entity) {

    console.log(state.dialog_store.dialog.max_id[entity], 'max_id_before-increment')

    if (['section', 'node', 'translate'].includes(entity)) {
      Vue.set(state.dialog_store.dialog.max_id, entity, state.dialog_store.dialog.max_id[entity] + 1)
    }
  },

  SET_CONSTANTS(state, constants) {
    state.dialog_store.dialog.constants = constants
  },

  SET_SECTIONS(state, sections) {
    state.dialog_store.dialog.sections = sections
  },

  ADD_SECTION(state, section) {
    state.dialog_store.dialog.sections.push(section)
  },

  CLONE_NODE_FIELD_TRANSLATES(state, {translate_from_id, translate_to_id, section_from_id, section_to_id}) {

    //Поиск переводов на всех языках
    for (let lang of state.bot_store.botLanguages) {
      //Если перевод найден
      if (lang.code && state.dialog_store.dialog.translations[section_from_id][lang.code] && state.dialog_store.dialog.translations[section_from_id][lang.code][translate_from_id]) {
        //Установка копии
        Vue.set(state.dialog_store.dialog.translations[section_to_id][lang.code], translate_to_id, state.dialog_store.dialog.translations[section_from_id][lang.code][translate_from_id])
      }
    }

  },

  ADD_NODE(state, node) {
    Vue.set(state.dialog_store.dialog.nodes, node.id, node)
  },

  ADD_SECTION_NODES(state, nodes) {
    if (size(nodes) > 0) {
      for (let node_id in nodes) {
        let node = nodes[node_id]
        if (node.id) {
          Vue.set(state.dialog_store.dialog.nodes, node.id, node)
        }
      }
    }
  },

  REMOVE_NODE_AND_UPDATE_CONNECTIONS(state, node) {

    //Удаление связей
    for (let n_id in state.dialog_store.dialog.nodes) {
      let n = state.dialog_store.dialog.nodes[n_id]

      //Поиск только в нодах текущей секции
      if (state.dialog_store.dialog.params.current_section_id === n.section_id) {

        //Нужно ли обновлять ноду
        let update_node = false

        //Если следующий элемент равен удаляемому
        if (n.next_element_id && n.next_element_id === node.id) {
          n.next_element_id = null
          update_node = true
        }

        //Поиск связи с удаляемым элементом в списке подэлементов
        if (n.next_elements_ids) {
          for (let next_el of n.next_elements_ids) {
            if (next_el.next_element_id === node.id) {
              next_el.next_element_id = null
              update_node = true
            }
          }
        }

        if (update_node) {
          Vue.set(state.dialog_store.dialog.nodes, n.id, n)
        }

      }
    }

    //Удаление ноды
    Vue.delete(state.dialog_store.dialog.nodes, node.id);
  },

  UPDATE_NODE(state, node) {
    Vue.set(state.dialog_store.dialog.nodes, node.id, node)
  },

  SET_ACTIVE_NODE_ID(state, node_id) {
    Vue.set(state.dialog_store, 'active_node_id', node_id)
  },

  SET_DIALOG_PARAMS(state, {field, value}) {
    Vue.set(state.dialog_store.dialog.params, field, value)
  },

  //Drawing
  SET_DRAWING_DROP_COORDINATES(state, {x, y}) {
    Vue.set(state.dialog_store.drawing, 'dropX', parseInt(x))
    Vue.set(state.dialog_store.drawing, 'dropY', parseInt(y))
  },
  //Drawing

  //Translates
  ADD_SECTION_TRANSLATIONS(state, {section_id, translations}) {

    if (!state.dialog_store.dialog.translations[section_id]) {
      Vue.set(state.dialog_store.dialog.translations, section_id, {})
    }

    for (let lang of state.bot_store.botLanguages) {
      if (lang.code) {
        if (!state.dialog_store.dialog.translations[section_id][lang.code]) {
          Vue.set(state.dialog_store.dialog.translations[section_id], lang.code, {})
        }
      }
    }

    if (size(translations) > 0) {
      for (let lang_code in translations) {
        let translations_lang_obj = translations[lang_code]

        for (let translate_id in translations_lang_obj) {
          if(state.dialog_store.dialog.translations?.[section_id][lang_code])
                        Vue.set(state.dialog_store.dialog.translations[section_id][lang_code], translate_id, translations_lang_obj[translate_id])
        }
      }
    }
  },
  SET_CURRENT_LANG_CODE(state, code) {
    Vue.set(state.dialog_store.dialog.params, 'current_lang_code', code)
  },
  ADD_TRANSLATE(state, {section_id, lang_code, translate_id, text}) {
    if (section_id && lang_code && translate_id) {
      Vue.set(state.dialog_store.dialog.translations[section_id][lang_code], translate_id, text)
    }
  },
  //Translates

  //constants
  ADD_CONSTANT(state, newConstant) {
    Vue.set(state.dialog_store.dialog.max_id, 'translate', state.dialog_store.dialog.max_id.translate + 1)

    // let constant = {
    //   name: '',
    //   value: state.dialog_store.dialog.max_id.translate,
    //   description: '',
    //   group_id: null
    // }

    state.dialog_store.dialog.constants.list.unshift(newConstant)
  },

  DELETE_CONSTANT(state, array_index) {

    //Получение элемента
    let el = state.dialog_store.dialog.constants.list[array_index]

    //Цикл по переводам и удаление переменных
    for (let lang_code in state.dialog_store.dialog.constants.translations) {
      if (el.value && lang_code && state.dialog_store.dialog.constants.translations[lang_code] && typeof state.dialog_store.dialog.constants.translations[lang_code][el.value] !== 'undefined') {
        Vue.delete(state.dialog_store.dialog.constants.translations[lang_code], el.value);
      }
    }

    //Удаление элемента массива
    state.dialog_store.dialog.constants.list.splice(array_index, 1);
  },

  CREATE_CONSTANTS_LANG_OBJ(state, lang_code) {
    Vue.set(state.dialog_store.dialog.constants.translations, lang_code, {})
  },

  ADD_CONSTANT_TRANSLATE(state, {lang_code, translate_id}) {
    Vue.set(state.dialog_store.dialog.constants.translations[lang_code], translate_id, '')
  },

  CHANGE_PARAMETER_NOT_SAVED_CONSTANT (state) {
    if(state.dialog_store.dialog.constants.list.length > 0) {
      for (let i in state.dialog_store.dialog.constants.list) {
        if(state.dialog_store.dialog.constants.list[i]?.not_saved) {
          state.dialog_store.dialog.constants.list[i].not_saved = false
        }

      }
    }
  },
  //constants

  CLEAR_DIALOG_TRANSLATIONS (state) {
    state.dialog_store.dialog.translations = {}
  },

  CLEAR_DIALOG_NODES (state) {
    state.dialog_store.dialog.nodes = {}
  },

  DELETE_NODES_AND_TRANSLATIONS_BY_SECTION_ID(state, sectionId) {
    //delete nodes
    for (const key in state.dialog_store.dialog.nodes) {
      if (state.dialog_store.dialog.nodes[key].section_id === sectionId)
        Vue.delete(state.dialog_store.dialog.nodes, key)
    }

    //delete translations
    Vue.delete(state.dialog_store.dialog.translations, sectionId)
  },

}


const getters = {

  //Dialog
  dialog: state => {
    return state.dialog_store.dialog
  },

  dialog_params: state => {
    return state.dialog_store.dialog.params
  },

  constants: state => {
    return state.dialog_store.dialog.constants
  },

  current_max_section_id: state => {
    return state.dialog_store.dialog.max_id.section
  },

  current_max_node_id: state => {
    return state.dialog_store.dialog.max_id.node
  },

  current_max_translate_id: state => {
    return state.dialog_store.dialog.max_id.translate
  },

  sections: state => {
    return state.dialog_store.dialog.sections
  },

  sections_obj: state => {
    let sections = cloneDeep(state.dialog_store.dialog.sections);
    let obj = {}

    for (let section of sections) {
      if (section.id) {
        obj[section.id] = section
      }
    }

    return obj
  },

  sections_size: state => {
    return state.dialog_store.dialog.sections.length
  },

  current_section_id: state => {
    return state.dialog_store.dialog.params.current_section_id
  },

  current_section_loaded: state => {
    return state.dialog_store.dialog.params.current_section_loaded
  },

  current_section: state => {
    if (state.dialog_store.dialog.params.current_section_id && state.dialog_store.dialog.sections[state.dialog_store.dialog.params.current_section_id]) {
      return state.dialog_store.dialog.sections[state.dialog_store.dialog.params.current_section_id]
    }
    return null
  },

  nodes: state => {
    return state.dialog_store.dialog.nodes
  },

  current_section_nodes: (state, getters) => {
    let nodes = {}

    if (getters.current_section_id) {
      for (let node_id in state.dialog_store.dialog.nodes) {
        let node = state.dialog_store.dialog.nodes[node_id]
        if (node.section_id && node.section_id === getters.current_section_id) {
          nodes[node_id] = node
        }
      }
    }
    return nodes
  },

  active_node: (state, getters) => {
    let node = null
    if (getters.active_node_id && state.dialog_store.dialog.nodes[getters.active_node_id]) {
      node = state.dialog_store.dialog.nodes[getters.active_node_id];

      if (!node.statistics) {
        node.statistics = {...getters.statistics};
      }
    }

    return node
  },

  default_node_keyboard_element: state => {
    return {text: null, value: null, json: null, json_obj: null, next_element_id: null}
  },

  node_validation_type_list: state => {
    return [
      'none',
      'phone',
      'email',
      'image',
      'integer',
      'regexp',
      'custom'
    ]
  },

  node_search_list_type_list: state => {
    return [
      'method',
      'list',
      'state'
    ]
  },


  node_slider_type_list: state => {
    return [
      'method',
      'state'
    ]
  },

  default_node_validation_object: state => {
    return {
      type: 'none',
      custom_type_name: null,
      min_symbols: null,
      max_symbols: null,
      min_value: null,
      max_value: null,
    }
  },


  messengers_list: state => {
    return ['viber', 'telegram', 'facebook', 'widget', 'whatsapp', 'custom_channel']
  },

  default_node: (state, getters) => {
    let def = {
      id: null,
      section_id: null,
      type: null,
      name: null,
      json: null,
      json_obj: null,
      add_menu: false,
      send_typing: false,
      inline_keyboard: false,
      messengers: getters.messengers_list,
      statistics: getters.statistics,
      params: {},
      x: 0,
      y: 0,
      next_element_id: null,
      next_elements_ids: []
    }
    return def
  },

  statistics: () => {
    return {
      name: null,
      position_name: null,
      position: null,
    };
  },

  default_message_node_params: (state, getters) => {
    let def = {
      text: null,
      json: null,
      json_obj: null,
    }
    return def
  },

  default_input_node_params: (state, getters) => {
    let def = {
      text: null,
      json: null,
      json_obj: null,
      validation: getters.default_node_validation_object
    }
    return def
  },

  default_keyboard_node_params: (state, getters) => {
    let def = {
      text: null,
      json: null,
      json_obj: null,
      validation: getters.default_node_validation_object
    }
    return def
  },

  default_image_node_params: (state, getters) => {
    let def = {
      action_name: null,
      description: null
    }
    return def
  },

  //node_search_list_type_list
  default_search_list_node_params: (state, getters) => {
    let def = {
      text: null,
      json: null,
      json_obj: null,
      type: 'method',
      method_name: null,
      state_param: null,
      list_id: null
    }
    return def
  },

  default_slider_node_params: (state, getters) => {
    let def = {
      text: null,
      description: null,
      json: null,
      json_obj: null,
      method_name: null,
      state_param: null
    }
    return def
  },

  default_connect_from_node_params: (state, getters) => {
    let def = {
      description: null,
      section_id: null,
      node_id: null,
    }
    return def
  },

  default_connect_to_node_params: (state, getters) => {
    let def = {
      connector_alias: null,
      description: null
    }
    return def
  },

  default_action_node_params: (state, getters) => {
    let def = {
      action_name: null,
      description: null
    }
    return def
  },

  active_node_id: state => {
    return state.dialog_store.active_node_id
  },
  //Dialog

  //Drawing
  drawing: state => {
    return state.dialog_store.drawing
  },

  drawing_drop_coordinates: state => {
    let def = {
      x: state.dialog_store.drawing.dropX,
      y: state.dialog_store.drawing.dropY
    }
    return def
  },
  //Drawing

  //Dialog translates
  //Текущий язык, если не выбран - берется дефолтный
  current_lang_code: state => {
    if (state.dialog_store.dialog.params.current_lang_code) {
      return state.dialog_store.dialog.params.current_lang_code
    } else if (state.bot_store.defaultLanguageCode) {
      return state.bot_store.defaultLanguageCode
    } else {
      return null
    }
  },

  translations_obj: state => {
    return state.dialog_store.dialog.translations
  },

  current_translations_obj: (state) => {
    let obj = {}
    if (state.dialog_store.dialog.params.current_section_id && state.dialog_store.dialog.translations[state.dialog_store.dialog.params.current_section_id]) {
      obj = state.dialog_store.dialog.translations[state.dialog_store.dialog.params.current_section_id]
    }
    return obj
  },

  //Текущая секция + текущий язык
  current_translations_lang_obj: (state, getters) => {
    let obj = {}
    if (getters.current_translations_obj && getters.current_lang_code && getters.current_translations_obj[getters.current_lang_code]) {
      obj = getters.current_translations_obj[getters.current_lang_code]
    }
    return obj
  },
  //Dialog translates

}

export default {
  state,
  mutations,
  actions,
  getters
}
